import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const Caption = makeShortcode("Caption");
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Anatomy`}</h2>
    <p><img parentName="p" {...{
        "src": "https://github.com/primer/design/assets/2313998/c5bf30c6-d66a-4708-90da-b5bfac3c034b",
        "alt": "A diagram of a segmented control with three buttons. The labeled parts of the segmented control are: the selected third button, the icon in the second button, and the text label in the second button."
      }}></img></p>
    <h2>{`Usage`}</h2>
    <h3>{`For implicitly saved settings`}</h3>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
      <Box flex={1} mdxType="Box">
        <p>{`The primary purpose of a segmented control is to pick a value from a list of options and immediately apply that selection. It's similar to a group of radio inputs except radio group selections need to be explicitly saved via form submission.`}</p>
      </Box>
      <img src="https://github.com/primer/design/assets/2313998/24017512-e1e5-47bb-9fbb-dc2ffb7e9c62" role="presentation" width="456" />
    </Box>
    <p>{`For more information about explicit and automatic save patterns, see the `}<a parentName="p" {...{
        "href": "/ui-patterns/saving"
      }}>{`saving pattern guidleines`}</a>{`.`}</p>
    <h3>{`To toggle content visibility`}</h3>
    <Note mdxType="Note">
      <p>{`  The current implementations of our segmented control do not support rendering segments as links (`}<inlineCode parentName="p">{`<a>`}</inlineCode>{` tags).
Continue to avoid using segmented controls that change the URL until we have a solution for rendering segments as links.`}</p>
    </Note>
    <p>{`Segmented controls may also be used to change how content in a view is rendered. The following use cases are an acceptable use for a segmented control:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Sort control`}</strong>{`: Activating a segment affects the order of a list of items.`}</li>
      <li parentName="ul"><strong parentName="li">{`Filter control`}</strong>{`: Activating a segment affects the visibility of items in a list, and there is a segment that shows the full list.`}</li>
      <li parentName="ul"><strong parentName="li">{`Content formatting picker`}</strong>{`: Activating a segment changes the formatting of the same content.`}</li>
    </ul>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <div>
    <img src="https://github.com/primer/design/assets/2313998/dbec961f-64b2-4701-8d81-d178dd6ab206" alt="A screenshot of a GitHub discussions list with the segmented segmented control for sorting highlighted. The sort options are 'Oldest', 'Newest', and 'Top'." width="456" />
    <Caption mdxType="Caption">As a sort control</Caption>
  </div>
  <div>
    <img src="https://github.com/primer/design/assets/2313998/6d3ddfc8-f8e9-44ca-ac5f-43cbdaaf8969" alt="A screenshot of the GitHub organization insights page with the segmented control for filtering highlighted. The options are 'All results' and 'Open security advisories'. " width="456" />
    <Caption mdxType="Caption">As a filter control</Caption>
  </div>
  <div>
    <img src="https://github.com/primer/design/assets/2313998/b56ef0a9-45b6-4cb0-9f7e-10921967ae36" alt="A screenshot of the GitHub billing dashboard with a chart showing costs. The segmented control to switch between the chart type is highlighted. The options are 'Line', 'Area', and 'Bar'" width="456" />
    <Caption mdxType="Caption">As a content formatting picker</Caption>
  </div>
    </Box>
    <p>{`If selecting a segment changes the URL, the segments should be rendered as `}<inlineCode parentName="p">{`<a>`}</inlineCode>{` tags.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "/ui-patterns/navigation#choosing-a-pattern-for-tabbed-navigation"
      }}>{`decision tree`}</a>{` for choosing a pattern for tabbed interfaces for more detailed guidance and alternative patterns.`}</p>
    <h2>{`Content`}</h2>
    <h3>{`Segments`}</h3>
    <p>{`A segmented control should have 2–5 choices with text labels, or up to 6 icon-only segments. Too many choices can be difficult to parse and take up too much horizontal space. If there are more than 5 choices, use another choice selection pattern such as a group of radio inputs or a dropdown menu.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/2313998/182705421-fb58f024-323f-48b8-81ae-70db5fc0aed1.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Use a segmented control for picking from 5 (6 for icon-only segments) or fewer options.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/2313998/182705422-2cb47d94-952c-4a7a-8f2f-627cc1ef3204.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use a segmented control for more than 5 options.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/2313998/182705424-d85bee5f-3996-4078-9185-6c494e7d6067.png" role="presentation" width="456" />
    <Caption mdxType="Caption">
      Use a segmented control for selecting from a list of static values. You may use an auxiliary dropdown adjacent to
      the segmented control.
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/2313998/182705425-c79d6522-1f0e-40d9-8c4d-78bef58cceae.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use a segmented control with a choice that is a dropdown.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Segment icons`}</h3>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Text as="p" mt="0" mdxType="Text">
    Icons can be rendered in each segment as a visual cue to help users understand their options.
  </Text>
  <img src="https://user-images.githubusercontent.com/2313998/182705434-baf679ea-14de-4b62-b83f-f833c1058694.png" role="presentation" width="456" />
    </Box>
    <h4>{`Effective visual cues`}</h4>
    <p>{`Be consistent with the way visual cues are used in each segment. Use either all icons, all text labels, or all text labels with icons.`}</p>
    <p>{`Do not change icons to communicate state.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://github.com/primer/design/assets/2313998/4b1bfdea-d222-4a2d-a60f-a6adfb7d33f1" role="presentation" width="456" />
    <Caption mdxType="Caption">Use icons as a visual cue to communicate what the choice does.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://github.com/primer/design/assets/2313998/777d35a1-37e8-4198-b9a3-d91a72a22de2" role="presentation" width="456" />
    <Caption mdxType="Caption">Change or add icons to indicate whether the choice is selected.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/2313998/182711267-c71a91e0-7bde-435b-a0e3-72ca4ea34e55.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Keep the visual cues in each segment consistent.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/2313998/182711268-f61ec450-fb10-41ba-b736-5fe1e5bddc87.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't mix visual cues between segments.</Caption>
  </Dont>
    </DoDontContainer>
    <h4>{`Icon-only segments`}</h4>
    <p>{`Use icons without a visible text label sparingly, and only with icons that are easily understood by an average GitHub user. When a segment has an icon without a visible text label, the text label should be shown in a tooltip.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/2313998/182705427-29e7dfe2-b714-4baf-96d9-2871b46eaf05.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Use icons without a visible label when the icon's meaning is obvious.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/2313998/182705428-112e7ebf-d483-49f1-aabf-44866c31b7f9.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Don't use obscure icons without a visible label.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Segment label text`}</h3>
    <p>{`Segmented control segment labels should be nouns or noun phrases that succinctly describe the choice. Segment labels may not wrap to multiple lines.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/2313998/182705434-baf679ea-14de-4b62-b83f-f833c1058694.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Phrase choice labels as nouns or noun phrases with as few characters as possible.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/2313998/182705435-e5ee0c9e-d4e7-4db5-b865-7f1001fb3e3e.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Phrase choice labels as an action or with unnecessarily long strings of text.</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Contextual information`}</h3>
    <Box as="figure" display="flex" alignItems="flex-start" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="figcaption" mt="0" mdxType="Box">
    <Text as="h4" fontSize={2} fontWeight="bold" m={0} mdxType="Text">
      Label
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Segmented controls must have a text label, even if the label is only accessible to screen readers. A visible label
      is the most direct way to identify what the control is used for, but the location and surrounding elements of the
      control often give enough context to visually hide the label.
    </Text>
    <Text as="h4" fontSize={2} fontWeight="bold" m={0} mdxType="Text">
      Caption
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      An optional caption may be used to provide additional context to help users make a selection or explain what
      effect the selection will have. Caption text should be as short as possible.
    </Text>
  </Box>
  <img src="https://user-images.githubusercontent.com/2313998/182711443-bf68d139-dfd9-477f-853e-de217a96f5d8.png" role="presentation" width="456" />
    </Box>
    <p>{`See the `}<a parentName="p" {...{
        "href": "#layout"
      }}>{`layout`}</a>{` section for ways to compose a segmented control with a label.`}</p>
    <h2>{`Interaction`}</h2>
    <p>{`Selections are mutually exclusive. Once a selection has been made, it can only be deselected by making a new selection.`}</p>
    <p>{`A segmented control does not require a "Save" button to apply the selection. When a choice is selected, the selection immediately affects an object, state, or view.`}</p>
    <h2>{`Layout`}</h2>
    <h3>{`Composing the control with a label and caption`}</h3>
    <h4>{`Single-column`}</h4>
    <Box display="flex" alignItems="flex-start" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
      <p>{`  A single-column layout is the same way form controls (for example, text inputs) are layed out. The single-column layout is useful for preserving horizontal space.`}</p>
  <img src="https://github.com/primer/design/assets/2313998/397dad08-8ae4-40fb-9b0b-c41208057161" role="presentation" width="456" />
    </Box>
    <h4>{`Two-column`}</h4>
    <p>{`A two-column layout can be used to:`}</p>
    <ul>
      <li parentName="ul">{`Align the control with adjacent elements`}</li>
      <li parentName="ul">{`Conserve vertical space`}</li>
      <li parentName="ul">{`Group related controls in a way that is easy to scan`}</li>
    </ul>
    <Box display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <div>
    <img src="https://github.com/primer/design/assets/2313998/a9a9230c-406f-42dc-9d41-eb160e00c5e7" role="presentation" />
  </div>
  <div>
    <img src="https://github.com/primer/design/assets/2313998/cb9a26c6-ad3b-42ba-b80f-fd921670f8d6" role="presentation" />
  </div>
    </Box>
    <h3>{`Fill available width`}</h3>
    <Box as="figure" display="flex" alignItems="flex-start" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <Text as="p" mt="0" mdxType="Text">
    A segmented control's width can stretch each button evenly to fill the width of the control's parent. This option is
    intended to make the segmented control easier to adapt into different layouts.
  </Text>
  <img src="https://user-images.githubusercontent.com/2313998/182705451-8eb238e0-7a4e-4c6c-a9ed-291e35ce1bf1.png" role="presentation" width="456" />
    </Box>
    <h3>{`Responsive design`}</h3>
    <p>{`There are cases where a segmented control needs to be placed in a spot that is too narrow to accommodate all of its options. In these cases, the control can be adapted to fit in the narrow area without sacrificing usability.`}</p>
    <Box as="figure" display="flex" alignItems="center" flexDirection={['column', 'column', 'column', 'column', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
  <figcaption>
    <Text as="h4" fontSize={2} fontWeight="bold" m={0} mdxType="Text">
      Convert to an action menu
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      Swapping out the segmented control for an action menu is especially helpful when:
      <Box as="ul" mt={1} pl="1rem" mdxType="Box">
        <li>The icons cannot be understood without visible text labels</li>
        <li>The control is still too wide when text labels are hidden</li>
      </Box>
    </Text>
  </figcaption>
  <img src="https://user-images.githubusercontent.com/2313998/182705449-0b054ec5-26e3-4bff-b5c0-313966badadf.png" role="presentation" width="456" />
    </Box>
    <Box as="figure" display="flex" alignItems="center" flexDirection={['column', 'column', 'column', 'column', 'row']} m={0} mb={5} sx={{
      gap: 3
    }} mdxType="Box">
  <figcaption>
    <Text as="h4" fontSize={2} fontWeight="bold" m={0} mdxType="Text">
      Only show icons
    </Text>
    <Text as="p" mt="0" mdxType="Text">
      If your segmented control uses icons that can be understood without a visible text label, then you can hide the
      text labels and rely only on icons. The text from the hidden label will still be visible in a tooltip.
    </Text>
  </figcaption>
  <img src="https://user-images.githubusercontent.com/2313998/182705450-6a68767c-f7e5-4e3d-972f-38d30267df71.png" role="presentation" width="456" />
    </Box>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/2313998/182705447-1f9ef99d-b6f2-4a98-af13-d34654236d30.png" role="presentation" width="456" />
    <Caption mdxType="Caption">
      Replace the segmented control with an action menu or use icons that are easily understood without text labels.
    </Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/2313998/182705446-bfa04d0b-dbfa-4d1b-b732-c50503d65735.png" role="presentation" width="456" />
    <Caption mdxType="Caption">Wrap the buttons to multiple lines, use an overflow button, or remove buttons</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Accessibility`}</h2>
    <p>{`A segmented control is treated like a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/ul"
      }}>{`list`}</a>{` where each `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/li"
      }}>{`list item`}</a>{` contains a button.`}</p>
    <p>{`Do not treat a segmented control like any of the following ARIA roles:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/web/accessibility/aria/roles/radiogroup_role"
        }}><inlineCode parentName="a">{`radiogroup`}</inlineCode></a>{`, which would require a save button to apply the changes.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tablist_role"
        }}><inlineCode parentName="a">{`tablist`}</inlineCode></a>{`, which is used to switch between `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tabpanel_role"
        }}>{`tab panels`}</a>{` of different content.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/toolbar_role"
        }}><inlineCode parentName="a">{`toolbar`}</inlineCode></a>{`, which is a collection of function buttons.`}</li>
    </ul>
    <p>{`These other roles have their own keyboard navigation rules and are treated differently by screen readers and other assistive technologies. Designs that treat segmented controls like tabs or radio buttons would be confusing for users who interact with the web using assistive technologies.`}</p>
    <h3>{`Keyboard navigation`}</h3>
    <Box as="figure" display="flex" alignItems="flex-start" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
      <div>
        <h4><inlineCode parentName="h4">{`Tab`}</inlineCode></h4>
        <p><inlineCode parentName="p">{`Tab`}</inlineCode>{` behaves just like it would in a list of focusable elements.`}</p>
        <p>{`When tabbing into the segmented control from an adjacent focusable element, the first or last button receives focus.`}</p>
        <ul>
          <li parentName="ul">{`Focus will be brought to the first segmented control button if focus is being moved from `}<em parentName="li">{`before`}</em>{` the segmented control.`}</li>
          <li parentName="ul">{`Focus will be brought to the last segmented control button if focus is being moved from `}<em parentName="li">{`after`}</em>{` the segmented control.`}</li>
        </ul>
        <h4><inlineCode parentName="h4">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="h4">{`Space`}</inlineCode></h4>
        <p>{`Pressing `}<inlineCode parentName="p">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="p">{`Space`}</inlineCode>{` while focused on a deselected button selects that button. This is the default behavior for buttons.`}</p>
        <p>{`If the focused button is already selected, pressing `}<inlineCode parentName="p">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="p">{`Space`}</inlineCode>{` should not deselect the button.`}</p>
        <h4><inlineCode parentName="h4">{`Arrow Left`}</inlineCode>{` or `}<inlineCode parentName="h4">{`Arrow Right`}</inlineCode></h4>
        <p>{`Unlike radio groups and toolbars, the left and right arrow keys do not change the focus or selection.`}</p>
      </div>
      <Box flexShrink={0} mdxType="Box">
  <CustomVideoPlayer width="456" autoPlay loop src="https://user-images.githubusercontent.com/2313998/183119490-af7db406-63e5-4395-ba2f-76941691b497.mp4" mdxType="CustomVideoPlayer" />
      </Box>
    </Box>
    <h3>{`Touch targets`}</h3>
    <Box as="figure" display="flex" flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']} m={0} mb={3} sx={{
      gap: 3
    }} mdxType="Box">
      <Text as="p" mt="0" mdxType="Text">Ensure segmented control buttons have a large enough touch target size. On devices that have a pointer with limited accuracy (such as touchscreen phones), provide a larger touch target area. The buttons should respond to hovers, clicks, and taps anywhere in the touch target area, even if it isn't directly on the control.</Text>
  <img src="https://user-images.githubusercontent.com/2313998/182705453-f0a68ed8-4e07-4ce3-8c09-0ead2d08b816.png" role="presentation" width="456" />
    </Box>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="SegmentedControl" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`Radio group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/underline-nav"
        }}>{`Underline nav`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/underline-panels"
        }}>{`Underline panels`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/navigation"
        }}>{`Navigation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/saving"
        }}>{`Saving`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      